<template>
  <main class="flex h-full">
    <div
      v-if="auth.isLoggedIn"
      class="relative w-2/12"
    >
      <div
        id="portoll-sidebar"
        class="bg-[#1E293B] h-full"
      >
        <slot name="sidebarContent" />
      </div>
    </div>

    <div
      class="grid grid-cols-12 h-screen"
      :class="{ 'w-10/12': auth.isLoggedIn, 'w-full': !auth.isLoggedIn }"
    >
      <div class="bg-slate-100 col-span-12 overflow-y-scroll">
        <slot name="mainContent" />
      </div>
    </div>
  </main>
</template>

<script lang="ts" setup>
  import {useAuthStore} from '~/stores/useAuthStore';

  const auth = useAuthStore();
</script>
